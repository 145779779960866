@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
*{
  box-sizing: border-box;
}
html, body, #root, .App {
    height: 100%;
    width: 100%;
    margin:0px;
    background-color: #34326a;
    font-family: 'Nunito';
}
